<template>
	<a-card title="" :bordered="false">
        <a-row class="payment-total" type="flex" justify="space-around">
            <a-col class="payment-total__col" :span="8">
                <div class="payment-total__title">待结算奖金
                    <a-tooltip title="结算奖金大于100，且认证通过并填写了收款信息可申请结算。">
                        <a-icon style="color:#e7a509" type="question-circle-o" />
                    </a-tooltip>
                </div>
                <div class="payment-total__amount">{{topData.waitAmount!=='' ? '￥'+topData.waitAmount : '--'}}</div>
                <a-button type="primary" :disabled="topData.waitAmount <= 100 || !collectCompleted" @click="toPaymentSend">申请结算</a-button>
            </a-col>
            <a-col class="payment-total__col" :span="8">
                <div class="payment-total__title">已结算奖金</div>
                <div class="payment-total__amount">{{topData.jiesuanAmount!=='' ? '￥'+topData.jiesuanAmount : '--'}}</div>
            </a-col>
            <a-col class="payment-total__col" :span="8">
                <div class="payment-total__title">总收入</div>
                <div class="payment-total__amount">{{topData.totalAmount!=='' ? '￥'+topData.totalAmount : '--'}}</div>
            </a-col>
        </a-row>
        <a-modal :footer="null" :visible="modelData.visible" :mask-closable="false" :keyboard="false">
            <div slot="closeIcon" class="modal-close" @click="modelData.visible = false;"><a-icon type="close-circle" /></div>
            <div class="modal-content">
                <h3 class="modal-content__tip">结算金额</h3>
                <div class="modal-content__price">￥{{ topData.waitAmount}}</div>
                <a-button type="primary" size="large" class="modal-content__btn" :lading="modelData.loading" @click="modalHandleOk">提交申请</a-button>
                <div class="modal-content__desc">
                    <p>申请提现后，7个工作日内完成付款</p>
                    <p>如有疑问，请与您对接的编辑沟通</p>
                </div>
            </div>
        </a-modal>
        <div v-if="initLoading" class="payment-loading">
            <a-spin size="large" tip="加载中，请稍后" />    
        </div>
        <template v-else>
            <a-table v-if="pagination.total > 0" :columns="columns" :data-source="currentData" :loading="pageLoading" :pagination="pagination" @change="tableChange" rowKey="create_time">
                <div slot="createTimeSlot" slot-scope="time">
                    {{formatTime(time, 'Y-M-D H:I')}}
                </div>
                <div style="color:#ff0000" slot="amountSlot" slot-scope="amount">
                    ￥{{amount}}
                </div>
                <div style="color:#ff0000" slot="taxAmountSlot" slot-scope="amount">
                    ￥{{amount}}
                </div>
                <div class="table-status" slot="statusSlot" slot-scope="status, record">
                    <template v-if="status == 1">
                        <!-- 审核通过 -->
                        <a-tooltip v-if="record.finance_status == -1" :title="record.log">
                            <a-button type="dashed" size="small" class="table-status__btn table-status__btn--close">拒绝打款<a-icon type="question-circle" /></a-button>
                        </a-tooltip>
                        <a-button v-if="record.finance_status == 0" type="primary" class="table-status__btn table-status__btn--no" size="small">审核通过，待打款</a-button>
                        <a-button v-if="record.finance_status == 2" type="primary" class="table-status__btn table-status__btn--no" size="small">打款中</a-button>
                        <a-button v-if="record.finance_status == 1" type="primary" class="table-status__btn table-status__btn--yes" size="small">已打款</a-button>
                    </template>
                    <template v-else>
                        <a-tooltip v-if="status == -1" :title="record.log">
                            <a-button type="dashed" size="small" class="table-status__btn table-status__btn--close">审核不通过<a-icon type="question-circle" /></a-button>
                        </a-tooltip>
                        <a-button v-if="status == 0" type="primary" class="table-status__btn table-status__btn--no" size="small">审核中</a-button>
                    </template>
                </div>
            </a-table>
            <a-empty v-else class="payment-empty" description="没有相关数据哦~">
            </a-empty>
        </template>
	</a-card>
</template>

<script>
import { formatTime } from '@/common/date';
import { mapState } from "vuex";
const columns = [
    {
        title: '申请时间',
		dataIndex: "create_time",
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'createTimeSlot' }
	},
    {
        title: '申请金额(元)',
		dataIndex: "amount",
        width: 100,
        align: 'center',
        scopedSlots: { customRender: 'amountSlot' }
	},
    {
        title: '实际支付(税后/元)',
		dataIndex: "tax_amount",
        align: 'center',
        width: 100,
        scopedSlots: { customRender: 'taxAmountSlot' }
	},
    {
        title: '付款状态',
		dataIndex: "status",
        align: 'center',
        width: 120,
        scopedSlots: { customRender: 'statusSlot' }
    }
];
export default {
    data() {
        return {
            dataset: {}, // 数据集合
            // 分页配置
            pagination: {
                current: 1,
                defaultPageSize: 50,
                total: 0
            },
			columns,
            initLoading: true,
            pageLoading: false,
            dateValue: ['', ''],
            // 头部汇总数据
            topData: {
                waitAmount: '', //待结算
                jiesuanAmount: '', //已结算
                totalAmount: '' //总收入
            },
            // 弹窗数据
            modelData: {
                visible: false, //显示
                loading: false,//提现加载
                ids: [],//可提现的id集合
                money: '',//申请提现金额
                startDate: '',//申请提现起始时间
                endDate: '',//申请提现截止时间
            }
        }
    },
    computed: {
        ...mapState({
			userInfo: (state) => state.user.user_info,
		}),
        // 收款信息是否填写
        collectCompleted() {
            return Object.prototype.toString.call(this.userInfo.collection_info) === '[object Object]';
        },
        // 单前分页数据
        currentData() {
            let thisPage = this.pagination.current;
            let thisData = this.dataset['page_'+ thisPage];
            return thisData ? thisData : [];
        }
    },
    mounted() {
        this.getTopData();
        this.getListData(true);
    },
    methods: {
        // 格式化时间
        formatTime(timestamp, format) {
            return formatTime(timestamp * 1000, format);
        },
        // 获取头部数据
        getTopData() {
            this.$api.withdrawTop().then(res => {
                // res = {"status":1,"error_code":0,"data":{"wait_amount":25,"jiesuan_amount":0,"total_amount":"25.00"},"msg":"","returns_time":1618881751};
                if(res.status) {
                    let resData = {
                        waitAmount: parseFloat(res.data.wait_amount).toFixed(2), 
                        jiesuanAmount: parseFloat(res.data.jiesuan_amount).toFixed(2), 
                        totalAmount: parseFloat(res.data.total_amount).toFixed(2)
                    }
                    this.topData = Object.assign({}, this.topData, resData);
                }else{
                    this.$message.error({ content: '获取数据失败请稍后再试~', key: 'message' });
                }
            })
        },
        // 获取列表数据,isinit(Boolean) 初始化加载
        getListData(isinit) {
            let thisPage = this.pagination.current;
            this.$api.withdrawGetlist({
                start_time: this.dateValue[0] == '' ? '' : this.dateValue[0].format('YYYY-MM-DD'),
                end_time: this.dateValue[1] == '' ? '' : this.dateValue[1].format('YYYY-MM-DD'),
                limit: this.pagination.defaultPageSize,
                page: thisPage
            }).then(res => {
                //res = {"status":1,"error_code":0,"data":{"list":[{"status":0,"amount":2838,"msg":"用户申请提现金额","log":"","pass_time":0,"income_ids":["1"],"create_time":1605004084,"finance_status":0,"start_time":1604419200,"end_time":1604419200}],"count":1},"msg":"","returns_time":1605004084}
                if(res.status) {
                    let listData = {};
                    listData['page_'+ thisPage] = res.data.list;
                    this.dataset = Object.assign({}, this.dataset, listData);
                    this.pagination.total = res.data.count;
                }
            }).finally(() => {
                if(isinit) {
                    this.initLoading = false;
                }else{
                    this.pageLoading = false;
                }
            })
        },
        // 表格分页变化
        tableChange(pagination, filters, sorter) {
            // 判断是否已经加载数据
            let current = pagination.current;
            this.pagination.current = current;
            if(!this.dataset['page_'+ current]) {
                this.pageLoading = true;
                this.getListData(false);
            }
        },
        // 更新查询
        updataSearch() {
            this.initLoading = true;
            this.dataset = {};
            this.pagination.current = 1;
            this.getListData(true);
        },
        // 申请提现
        toPaymentSend() {
            this.modelData.visible = true;
        },
        // 提现弹窗确认
        modalHandleOk() {
            this.modelData.loading = true;
            this.$api.withdrawShenqing().then(res => {
                if(res.status) {
                    this.$message.success({ content: '提现成功', key: 'message' });
                    // 刷新列表数据
                    this.initLoading = true;
                    this.dataset = {};
                    this.pagination.current = 1;
                    this.getListData(true);
                    // 刷新头部数据
                    this.getTopData();
                }else{
                    this.$message.error({ content: res.msg, key: 'message' });
                }
            }).finally(() => {
                this.modelData.loading = false;
                this.modelData.visible = false;
            })
        }
    }
};
</script>

<style lang="less" scoped>
.orders-search{
    margin: 20px 0;
}
// table-slot 订单信息
.order-info{
    font-size: 14px;
    p{
        margin-bottom: 0;
    }
}
// table-slot 订单价格
.table-money{
    color: @color-default;
    font-size: 18px;
    font-weight: 700;
}
// table-slot订单状态
.table-status{
    &__btn{
        font-size: 14px;
        // 未支付
        &--yes{
            border-color: #67c23a;
            background-color: #67c23a;
            
            color: #fff;
        }
        // 审核失败
        &--close{
            border-color: #f5222d;
            background-color: #f5222d;
            color: #fff;
        }
        // 已上线
        &--no{
            border-color: #ff9800;
            background-color: #ff9800;
            color: #f0f0f0;
        }
    }
}
.payment-empty{
    padding: 180px 0;
}
// 进入加载状态
.payment-loading{
    text-align: center;
    padding: 100px 0;
    height: 320px;
}
.payment-total{
    margin: 0 0 10px;
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
    border-radius: 5px;
    &__col{
        padding: 30px 0 20px;
        text-align: center;
    }
    &__title{
        color: #999;
    }
    &__amount{
        font-size: 24px;
        font-weight: 700;
        padding: 6px 0;
    }
}
// 弹窗样式
.modal-close{
    font-size: 30px;
}
.modal-content{
    text-align: center;
    &__tip{
        font-size: 20px;
        font-weight: normal;
    }
    &__price{
        padding: 8px 0;
        font-size: 26px;
        color: @color-default;
    }
    &__desc{
        margin-top: 20px;
        p{
            margin-bottom: 0;
        }
    }
}

</style>
